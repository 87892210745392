body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.App {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.vmeste {
  height: 100%;
  width: 100%;
  display: flex;
}

.Vmestedwa {
  margin-left: 32rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}


body {
  transition: background-color 0.3s, color 0.3s;
}

body.dark-mode {
  background-color: #333333;
  color: #e0e0e0;
}

.NavContainer.dark-mode,
.Vmestedwa.dark-mode,
.vmeste.dark-mode {
  background-color: #333;
  color: #f1f1f1;
}


@media (max-width:768px) {
  .Vmestedwa {
    margin-left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}