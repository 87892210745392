/* Base CSS Variables */
:root {
    --background-color-light: #f0f2f5;
    --chat-background-light: #fff;
    --message-user-background-light: #666CFF;
    --message-bot-background-light: #f1f0f0;
    --input-border-light: #ccc;
    --button-background-light: #666CFF;
    --button-hover-background-light: #666CFF;
    --text-color-light: #333;

    --background-color-dark: #121212;
    --chat-background-dark: #1e1e1e;
    --message-user-background-dark: #666CFF;
    --message-bot-background-dark: #333;
    --input-border-dark: #555;
    --button-background-dark: #666CFF;
    --button-hover-background-dark: #555;
    --text-color-dark: #e0e0e0;
}

/* Apply dark mode styles */
body.dark-mode {
    --background-color: var(--background-color-dark);
    --chat-background: var(--chat-background-dark);
    --message-user-background: var(--message-user-background-dark);
    --message-bot-background: var(--message-bot-background-dark);
    --input-border: var(--input-border-dark);
    --button-background: var(--button-background-dark);
    --button-hover-background: var(--button-hover-background-dark);
    --text-color: var(--text-color-dark);
}

.dashboard-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 91vh;
    background-color: var(--background-color);
    padding: 20px;
}

.chat-container {
    width: 100%;
    max-width: 900px;
    height: 100%;
    overflow-y: auto;
    background-color: var(--chat-background);
    border: 1px solid var(--input-border);
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.chat-message {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    max-width: 100%;
    word-wrap: break-word;
    text-align: end;
}

.chat-message.user {
    width: auto;
    background-color: var(--message-user-background-light);
    color: #fff;
    align-self: flex-end;
}

.chat-message.bot {
    background-color: var(--message-bot-background);
    color: var(--text-color);
    align-self: flex-start;
}

.dashboard-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 10px;
    width: 100%;
    max-width: 900px;
    border-radius: 8px;
    background-color: var(--chat-background);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dashboard-input {
    width: 100%;
    flex: 1;
    padding: 10px;
    margin-right: 10px;
    border: 1px solid var(--input-border);
    border-radius: 4px;
    font-size: 16px;
}

.dashboard-input:focus {
    outline: none;
    border-color: var(--button-hover-background-light);
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.dashboard-button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: var(--button-hover-background-light);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dashboard-button:hover {
    background-color: var(--button-hover-background);
}

.typing-text {
    white-space: pre-wrap;
    font-family: monospace;
    color: var(--text-color);
}

/* Анимация загрузки */
.loading-indicator {
    text-align: center;
    font-size: 1.2em;
    margin-top: 10px;
}

.BotOtvet {
    font-family: monospace;
    overflow: hidden;
    white-space: wrap;
    display: inline-block;
    width: 100%;
    text-align: start;
}

.typing {
    display: inline-block;
    animation: typing 2s steps(30, end), blink 0.5s step-end infinite alternate;
}

@keyframes typing {
    from {
        width: 0
    }
}

@keyframes blink {
    50% {
        border-color: transparent
    }
}


@media (max-width:768px) { 
    .dashboard-container {
        position: absolute;
        bottom:0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        height: 91vh;
        background-color: var(--background-color);
        padding: 20px;
    }
}