:root {
    --primary-color: #D69D0B;
    --secondary-color: #545454;
    --background-color: #ffffff;
    --border-color: #ccc;
    --shadow-color: rgba(0, 0, 0, 0.2);
    --link-color: #00A739;
    --text-color: #000000;
    --text-color-dark: #cccccc;
    --profile-border-color: #9694be;
    --left-nav-bg-color: #9694be;
    --left-nav-icon-color: #ffffff;
    --menu-bg-color: #f0f0f0;
}

body.dark-mode {
    --secondary-color: #fff;
    --background-color: #121212;
    --border-color: #333333;
    --shadow-color: rgba(255, 255, 255, 0.1);
    --link-color: #00ff00;
    --text-color: #ffffff;
    --text-color-dark: #888888;
    --profile-border-color: #444444;
    --left-nav-bg-color: #444444;
    --left-nav-icon-color: #cccccc;
    --menu-bg-color: #333333;
}

.NavContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Poppins", sans-serif;
    padding: 0 20px;
    height: 70px;
    border-bottom: 1px solid var(--border-color);
    background-color: var(--background-color);
}

.NavContant {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.NavContantLeft {
    display: flex;
    align-items: center;
    gap: 10px;
}

.LeftNavStatus {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.LeftNavStatus h4 {
    padding: 0;
    margin: 0;
    color: var(--text-color);
}

.LeftNavStatus p {
    padding: 0;
    margin: 0;
    font-size: .8rem;
    color: var(--link-color);
    display: flex;
    align-items: center;
}

.LeftNavStatus p span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    overflow: hidden;
    height: 20px;
    width: 20px;
}

.LeftNavImg {
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    border-radius: 50%;
    border: 1px solid var(--profile-border-color);
    background-color: var(--left-nav-bg-color);
}

.LeftNavImg p {
    font-size: 25px;
    color: var(--left-nav-icon-color);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}

.NavContantRight {
    display: flex;
    gap: 25px;
}

.Upgrade {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Upgrade button {
    border: none;
    background: none;
    color: var(--primary-color);
    font-size: 18px;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
}

.Darkmode {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Darkmode button {
    border: none;
    background: none;
    outline: none;
    color: var(--secondary-color);
    font-size: 18px;
    cursor: pointer;
}

.Profile {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Profile button {
    display: flex;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    font-size: 15px;
    gap: 10px;
}

.Profile-img {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 1px solid var(--profile-border-color);
    overflow: hidden;
}

.Profile-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Profile-text {
    display: flex;
    align-items: flex-end;
    height: 35px;
}

.Profile-text p.dark-mode {
    color: var(--text-color-dark);
}

.Profile-text p {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: flex-end;
    color: var(--text-color);
}

.DropdownMenu {
    width: 100%;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    box-shadow: 0 8px 16px var(--shadow-color);
    z-index: 1000;
}

.DropdownMenu ul {
    list-style: none;
    margin: 0;
    padding: 10px;
}

.DropdownMenu ul li {
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 8px;
    color: var(--text-color);
}

.DropdownMenu ul li:hover {
    background-color: var(--menu-bg-color);
}

@media (max-width: 768px) {
    .Upgrade {
        display: none;
    }

    .Darkmode {
        display: none;
    }

    .LeftNavStatus {
        display: none;
    }

    .LeftNavImg {
        display: none;
    }

    .Profile-text {
        display: none;
    }

    .DropdownMenu {
        width: 14rem;
        position: absolute;
        top: 100%;
        right: 0;
        background-color: var(--background-color);
        border: 1px solid var(--border-color);
        border-radius: 8px;
        box-shadow: 0 8px 16px var(--shadow-color);
        z-index: 1000;
    }

    .Standart {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .Standart h4 {
        font-size: 17px;
        color: var(--secondary-color);
        font-weight: 600;
        margin: 0;
    }

    .Standart p {
        font-size: 12px;
        color: var(--link-color);
        display: flex;
        align-items: center;
        height: 20px;
        margin: 0;
    }


    .Standart p span {
        font-size: 25px;
        display: flex;
        align-items: center;
    }

    .DropdownMenu ul li {
        height: 55px;
        width: 100%;
        border: 1px solid var(--border-color);
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .DropdownMenu ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: center;
    }

    .Ulutshit button {
        font-size: 17px;
        color: var(--primary-color);
        font-weight: 600;
    }

    .darkjala button{
        color: var(--secondary-color);
    }

    .NavContainer {
        width: 100%;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Poppins", sans-serif;
        padding: 0 20px;
        height: 70px;
        border-bottom: 1px solid var(--border-color);
        background-color: var(--background-color);
        z-index: 10;
    }
}