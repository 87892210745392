/* Base CSS Variables */
:root {
    --background-color: white;
    --text-color: black;
    --container-background: white;
    --box-background: aliceblue;
    --box-border-color: #65666549;
}

/* Dark Mode CSS Variables */
body.dark-mode {
    --background-color: #121212;
    --text-color: #e0e0e0;
    --container-background: #333;
    --box-background: #1e1e1e;
}

/* Apply variables to elements */
.LeftContainer {
    padding: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-family: "Poppins", sans-serif;
    position: fixed;
    border: 1px solid var(--box-border-color);
    border-bottom:none;
    background-color: var(--container-background);
    height: 96.5vh;
    transition: background-color 0.3s, color 0.3s;
}

.LeftContant {
    width: 30rem;
    display: flex;
    flex-direction: column;
}

.LeftTitle {
    text-align: start;
}

.LeftTitle p {
    font-size: 30px;
    color: var(--text-color);
}

.LeftHistory {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 90%;
    position: relative;
}

.HistoryBox {
    width: 96%;
    word-break: wrap;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--box-background);
    border-radius: 8px;
    padding: 0 10px;
}

.LeftDown {
    position: absolute;
    bottom: 0;
    width: 90%;
    display: flex;
    justify-content: center;
}

/* Default styles for larger screens */
.LeftContainer {
    /* Your existing styles */
}

/* Styles for screens smaller than 768px */
@media (max-width: 768px) {
    .LeftContainer {
        display: none; /* Hide the LeftContainer */
    }
    
    .BurgerMenu {
        display: block; /* Show the burger menu */
    }
} 

.BurgerMenu {
    display: none;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1000;
    font-size: 24px;
    cursor: pointer;
}

.BurgerMenuContent {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 999;
    padding: 20px;
}

.BurgerMenuContent.open {
    transform: translateX(0);
}

@media (max-width: 768px) {
    .LeftContainer {
        display: none; /* Hide the LeftContainer */
    }
    
    .BurgerMenu {
        display: block; /* Show the burger menu icon */
    }
} 
